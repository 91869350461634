import { Button, Divider, Grid, Typography, useMediaQuery } from '@mui/material';
import {
    ArrayField,
    Create,
    CreateButton,
    Datagrid,
    DateField,
    DateInput,
    DeleteButton,
    Edit,
    EditButton,
    FileField,
    FileInput,
    FormDataConsumer,
    FunctionField,
    List,
    ListButton,
    ReferenceInput,
    SaveButton,
    SelectInput,
    SimpleForm,
    SimpleList,
    TextField,
    TextInput,
    Toolbar,
    choices,
    downloadCSV,
    required,
    useRecordContext,
    useRedirect,
    useRefresh,
    useTranslate,
    DatagridConfigurable
} from 'react-admin';
import { ENDPOINTS } from '../../api/JsonDataProvider';
import _ from 'lodash';
import moment from 'moment';
import DateTimeField from '../../field/DateTimeField';
import { DefaultPagination } from '../../pagination/Pagination';
import jsonExport from 'jsonexport/dist';
import { ChevronLeft, PrintOutlined } from '@mui/icons-material';
import { useReactToPrint } from 'react-to-print';
import { forwardRef, memo, useCallback, useEffect, useRef, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { COUNTRIES, STATES } from '../../config/locations';
import { postcodeValidator } from 'postcode-validator';
import CircularProgress from '@mui/material/CircularProgress';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { FieldWrapper, FullListActions } from '../../actions/ListActions';

const exporter = (workorders) => {
    const workorderForExport = workorders.map((workorder) => {
        let workorderForExport = {};
        workorderForExport.wo_number = workorder.woNumber;
        workorderForExport.site_name = workorder.companySite ? workorder.companySite.site.siteName : workorder.workOrderOtherLocation.siteName;
        workorderForExport.company_name = workorder.companySite ? workorder.companySite.site.company.companyName : workorder.workOrderOtherLocation.company.companyName;
        workorderForExport.representative_name = workorder.representativeName;
        workorderForExport.cost_center = workorder.costCenterId;
        workorderForExport.po_wo_number = workorder.poWoNumber;
        workorderForExport.requested_start_date = workorder.requestedStartDate;
        workorderForExport.start_date = workorder.startDate;
        workorderForExport.end_date = workorder.endDate;
        workorderForExport.status = workorder.status;
        workorderForExport.type = workorder.type;
        workorderForExport.created_by = `${workorder.createdBy.user.firstName} ${workorder.createdBy.user.lastName}`;
        workorderForExport.created_at = workorder.createdAt;
        return workorderForExport;
    });
    jsonExport(
        workorderForExport,
        {
            headers: ['wo_number', 'site_name', 'company_name', 'representative_name', 'cost_center', 'po_wo_number', 'requested_start_date', 'start_date', 'end_date', 'status', 'type', 'created_by', 'created_at'],
        },
        (err, csv) => {
            downloadCSV(csv, 'workorder_list');
        }
    );
};


const TYPES = [
    { id: "NEW_REQUEST", name: "locspect.resource.workOrders.fields.typeInput.NEW_REQUEST" },
    { id: "CHANGE", name: "locspect.resource.workOrders.fields.typeInput.CHANGE" },
    { id: "PERIODICAL", name: "locspect.resource.workOrders.fields.typeInput.PERIODICAL" },
    { id: "COMPLAINT_ACTION_REQUIRED", name: "locspect.resource.workOrders.fields.typeInput.COMPLAINT_ACTION_REQUIRED" }
];
const STATUS = [
    { id: "OPEN", name: "locspect.resource.workOrders.fields.statusInput.OPEN" },
    { id: "CLOSED", name: "locspect.resource.workOrders.fields.statusInput.CLOSED" }
];

const FULL_STATES = []
Object.values(STATES).forEach(state => Object.entries(state).forEach(entry => FULL_STATES.push({ id: entry[0], name: entry[1] })))
const filter = [
    <TextInput parse={(value) => {
        return _.isEmpty(value) ? '' : value.replace(/\D/g,'');
    }} source="woNumber_eq" label="locspect.resource.workOrders.fields.number" />,
    <TextInput
        source={"companySite->site->company->companyName__or__workOrderOtherLocation->company->companyName_like"}
        label="locspect.resource.workOrders.fields.company" />,
    <SelectInput
        label="locspect.resource.sites.fields.state"
        source={"companySite->site->address->state__or__workOrderOtherLocation->address->state_like"}
        fullWidth
        choices={FULL_STATES} />,
    <SelectInput
        label="locspect.resource.workOrders.fields.status"
        source={"status_like"}
        fullWidth
        choices={STATUS} />,
    <SelectInput
        label="locspect.resource.workOrders.fields.type"
        fullWidth
        source={"type_like"}
        choices={TYPES} />,
    <DateInput
        parse={v => moment(v).toISOString()}
        source="requestedStartDate_ge"
        label="locspect.resource.workOrders.fields.requestedStartDateAfter"
    />,
    <DateInput
        parse={v => moment(v).endOf('day').toISOString()}
        source="requestedStartDate_le"
        label="locspect.resource.workOrders.fields.requestedStartDateBefore"
    />,
];

const SupervisorEditButton = ({ role }) => {
    const record = useRecordContext();
    if (role === "ADMIN" || (role === "SUPERVISOR" && record.status !== "CLOSED")) {
        return <EditButton />
    }
}

export const WorkOrderList = (props) => {
    const { role } = props;
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const translate = useTranslate();

    return (
        <List actions={<FullListActions />} perPage={25} pagination={<DefaultPagination />} hasCreate={true} exporter={exporter} filters={filter} sort={{ field: 'woNumber', order: 'DESC' }}>
            {isSmall ? (
                <SimpleList primaryText={(record) => `${record.woNumber} - ${record && record.companySite ? record.companySite.site.siteName : record.workOrderOtherLocation.siteName}`}
                    secondaryText={record => new Date(record.startDate).toLocaleDateString()}
                    tertiaryText={record => record.status}
                    linkType={record => record.canEdit ? "edit" : "show"}
                >
                    <CreateButton /></SimpleList>
            ) : (
                <DatagridConfigurable bulkActionButtons={false}>
                    <TextField label="locspect.resource.workOrders.fields.number" source="woNumber" />
                    <FunctionField label="locspect.resource.workOrders.fields.companySite" render={record => (record && record.companySite) ? record.companySite.site.siteName : record.workOrderOtherLocation.siteName} />
                    <FunctionField label="locspect.resource.workOrders.fields.company" render={record => (record && record.companySite) ? record.companySite.site.company.companyName : record.workOrderOtherLocation.company.companyName} />
                    <TextField label="locspect.resource.workOrders.fields.representativeName" source="representativeName" />
                    <TextField label="locspect.resource.workOrders.fields.costCenter" source="costCenterId" />
                    <TextField label="locspect.resource.workOrders.fields.poWoNumber" source="poWoNumber" />
                    <DateField label="locspect.resource.workOrders.fields.requestedStartDate" source="requestedStartDate" />
                    <DateField label="locspect.resource.workOrders.fields.startDate" source="startDate" />
                    <DateField label="locspect.resource.workOrders.fields.endDate" source="endDate" />
                    <FunctionField label="locspect.resource.workOrders.fields.status" render={record => translate('locspect.resource.workOrders.fields.statusInput.' + record.status)} />
                    <FunctionField label="locspect.resource.workOrders.fields.type" render={record => translate('locspect.resource.workOrders.fields.typeInput.' + record.type)} />
                    <FunctionField label="locspect.resource.workOrders.fields.createdBy" render={record => `${record.createdBy.user.firstName} ${record.createdBy.user.lastName}`} />
                    <DateTimeField label="locspect.resource.workOrders.fields.createdAt" source="createdAt" />
                    <FunctionField label="locspect.resource.workOrders.fields.updatedBy" render={record => record.updatedBy ? `${record.updatedBy.user.firstName} ${record.updatedBy.user.lastName}` : ''} />
                    <DateTimeField label="locspect.resource.workOrders.fields.updatedAt" source="updatedAt" />
                    <FieldWrapper label="ra.action.edit">
                        <SupervisorEditButton role={role} />
                    </FieldWrapper>
                    {role === "ADMIN" &&
                        <FieldWrapper label="ra.action.delete">
                            <DeleteButton />
                        </FieldWrapper>
                    }
                </DatagridConfigurable>
            )}
        </List>
    );
};

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

const ImageListItemMemo = memo(({ v, isPrinting = false }) => {
    const [loaded, setLoaded] = useState(isPrinting);
    const url = v.type === "image/heic" ? v.path + "_converted" : v.path;
    const [retryCounter, setRetryCounter] = useState(0);

    const onImageLoaded = useCallback(() => {
        setLoaded(true)
    }, []);

    const onImageError = useCallback(async () => {
        if (retryCounter < 5) {
            try {
                if (retryCounter === 4 && v.type === "image/heic") {
                    await fetch("https://heic-to-jpg-service-6be4xppwvq-nn.a.run.app/convert?url=" + encodeURIComponent(v.path));
                } else {
                    await sleep(3000)
                }
            } catch (err) {
                console.log(err);
            }
            setRetryCounter(retryCounter + 1);
        } else {
            setLoaded(true)
        }
        // eslint-disable-next-line
    }, []);

    return <ImageListItem key={v.id}>
        {!loaded && <CircularProgress hidden={loaded} />}
        <img key={`${v.id}-${retryCounter}`} srcSet={url} src={url} alt={v.name} title={v.name} onLoad={onImageLoaded} onError={onImageError} />
    </ImageListItem>
}, (prev, next) => {
    return prev.v.type === next.v.type && prev.v.path === next.v.path;
})

const FileInputInput = () => {
    const record = useRecordContext();
    if (record.id && record.type && record.path && record.type.startsWith("image")) {
        return <a href={record.path} >
            <span style={{ color: "black" }}>{record.name}</span>
            <ImageListItemMemo v={record} />
        </a>
    }
    return <FileField source="src" title="title" target={"_blank"} />
}

const WorkOrderImageList = ({ isPrinting, record, status, type, source, translationLabel, role }) => {
    const translate = useTranslate();
    const printingStyle = { pageBreakBefore: 'always', visibility: 'visible' }
    return (
        <>
            {
                !isPrinting &&
                <Grid item xs={4} sm={8} md={!isPrinting ? 6 : 12} style={{ pageBreakBefore: isPrinting ? 'always' : 'auto' }}>
                    <FileInput
                        multiple
                        source={source}
                        accept={"image/*, application/pdf"}
                        validate={status === "CLOSED" && role === "SUPERVISOR" && type !== "CHANGE" ? [required()] : []}
                        maxSize={15000000}
                        label={translationLabel}>
                        <FileInputInput />
                    </FileInput>
                </Grid>
            }
            {
                isPrinting &&
                <Grid item xs={4} sm={8} md={12} style={printingStyle}>
                    <Typography variant="h3">
                        {translate(translationLabel)}
                    </Typography>
                    <Divider />
                    <ImageList cols={2} gap={20}>
                        {record && record[source] && record[source].filter(v => v.type.startsWith('image')).map(v => {
                            return <ImageListItemMemo key={v.path} v={v} isPrinting={isPrinting} />
                        })}
                    </ImageList>
                </Grid>
            }
        </>
    )
}

const WorkOrderEditComponent = forwardRef((props, ref) => {
    const { managedcompanies, mode, isPrinting } = props;
    const translate = useTranslate();
    const record = useRecordContext();
    const creator = record && record.createdBy ? `${record.createdBy.user.firstName} ${record.createdBy.user.lastName}` : "";
    const updator = record && record.updatedBy ? `${record.updatedBy.user.firstName} ${record.updatedBy.user.lastName}` : "";
    const status = useWatch({ name: 'status' });
    const type = useWatch({ name: 'type' });
    const companySiteId = useWatch({ name: 'companySiteId' });
    let siteContent = undefined;
    const [country, setCountry] = useState(record && record.workOrderOtherLocation && record.workOrderOtherLocation.address ? record.workOrderOtherLocation.address.country : 'CA');
    let selectCountries = [];
    let selectStates = [];

    for (const [key, value] of Object.entries(COUNTRIES)) {
        selectCountries.push({
            id: key,
            name: value,
        });
    }

    if (!_.isEmpty(country) && country in STATES) {
        for (const [key, value] of Object.entries(STATES[country])) {
            selectStates.push({
                id: key,
                name: value,
            });
        }
    }

    if ((companySiteId === undefined && (!record || _.isEmpty(record.companySite))) || companySiteId === null) {
        siteContent = (
            <>
                <Grid item xs={4} sm={4} md={4}>
                    <TextInput
                        source="workOrderOtherLocation.siteName"
                        label="locspect.resource.workOrders.fields.siteName"
                        validate={[required()]}
                        fullWidth
                    />
                </Grid>

                <Grid item xs={4} sm={4} md={4}>
                    <TextInput
                        source="workOrderOtherLocation.address.street"
                        label="locspect.resource.workOrders.fields.street"
                        validate={[required()]}
                        fullWidth
                    />
                </Grid>

                <Grid item xs={4} sm={4} md={4}>
                    <TextInput
                        source="workOrderOtherLocation.address.city"
                        label="locspect.resource.workOrders.fields.city"
                        validate={[required()]}
                        fullWidth
                    />
                </Grid>

                <Grid item xs={4} sm={4} md={4}>
                    <SelectInput
                        fullWidth
                        allowEmpty={false}
                        label="locspect.resource.workOrders.fields.country"
                        source="workOrderOtherLocation.address.country"
                        choices={selectCountries}
                        onChange={(event) => {
                            setCountry(event.target.value);
                        }}
                        validate={[
                            required('The country is required'),
                            choices(Object.keys(COUNTRIES), 'Please choose one of the values'),
                        ]}
                    />
                </Grid>
                <Grid item xs={4} sm={4} md={4}>
                    <SelectInput
                        fullWidth
                        disabled={_.isEmpty(country)}
                        label="locspect.resource.workOrders.fields.state"
                        source="workOrderOtherLocation.address.state"
                        choices={selectStates}
                        defaultValue={undefined}
                        validate={[
                            required('The state is required'),
                            choices(Object.keys(STATES[country]), 'Please choose one of the values'),
                        ]}
                    />
                </Grid>
                <Grid item xs={4} sm={4} md={4}>
                    <TextInput
                        fullWidth
                        disabled={_.isEmpty(country)}
                        label="locspect.resource.workOrders.fields.zip"
                        source="workOrderOtherLocation.address.zip"
                        validate={[
                            (value) =>
                                !_.isEmpty(value) ? postcodeValidator(value, country) ? undefined : 'Code is invalid for the country' : undefined,
                        ]}
                    />
                </Grid>
            </>
        )
    }
    let selectedCompanyId = managedcompanies ? managedcompanies[0].managedCompany.id : undefined;
    if (record && record.id) {
        if (record.companySite && record.companySite.site && record.companySite.site.company) {
            selectedCompanyId = record.companySite.site.company.id;
        } else if (record.workOrderOtherLocation && record.workOrderOtherLocation.company) {
            selectedCompanyId = record.workOrderOtherLocation.company.id
        }
    }

    return (
        <Grid ref={ref} container p={1} spacing={{ xs: 2, md: 4 }} columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid item xs={4} sm={8} md={4}>
                <Typography variant="h6" gutterBottom>
                    {translate("locspect.resource.workOrders.label")} {record && record.id ? " #" + record.woNumber : ""}
                </Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
                <SelectInput
                    label="locspect.resource.workOrders.fields.status"
                    defaultValue={"OPEN"}
                    defaultChecked="OPEN"
                    validate={required()}
                    fullWidth
                    source={"status"}
                    choices={STATUS} />
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
                <SelectInput
                    label="locspect.resource.workOrders.fields.type"
                    defaultValue={"NEW_REQUEST"}
                    defaultChecked="NEW_REQUEST"
                    validate={required()}
                    fullWidth
                    source={"type"}
                    choices={TYPES} />
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
                <DateInput parse={v => moment(v).toISOString()} fullWidth defaultValue={moment()} validate={[required()]} source='requestedStartDate' label="locspect.resource.workOrders.fields.requestedStartDate" />
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
                <DateInput parse={v => moment(v).toISOString()} fullWidth source='startDate' validate={status === "CLOSED" ? [required()] : []} label="locspect.resource.workOrders.fields.startDate" />
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
                {type !== "CHANGE" && <DateInput parse={v => moment(v).toISOString()} fullWidth source='endDate' validate={status === "CLOSED" ? [required()] : []} label="locspect.resource.workOrders.fields.endDate" />}
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
                <SelectInput
                    label="locspect.resource.workOrders.fields.company"
                    defaultValue={selectedCompanyId}
                    validate={required()}
                    fullWidth
                    source={"managedCompanyId"}
                    choices={
                        managedcompanies.map(v => { return { id: v.managedCompany.id, name: v.managedCompany.companyName } })
                    } />
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
                <FormDataConsumer>
                    {({ formData, ...rest }) => (
                        !_.isEmpty(formData.managedCompanyId) ?
                            <ReferenceInput
                                source="companySiteId"
                                reference={ENDPOINTS['managed/company/sites/min'].name}
                                filter={{
                                    "site.company.id_eq": formData.managedCompanyId
                                }}
                                sort={{ field: "site.siteName", order: 'ASC' }}
                                perPage={2000}
                            >
                                <SelectInput emptyValue={""} emptyText={""} defaultValue={record && record.id ? record.companySite && record.companySite.id : undefined} validate={[]} fullWidth label="locspect.resource.workOrders.fields.companySite" {...rest} optionText={"site.siteName"} />
                            </ReferenceInput> : <SelectInput source="companySiteId" validate={[required()]} fullWidth label="locspect.resource.workOrders.fields.companySite" choices={[]} {...rest} />
                    )}
                </FormDataConsumer>
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
                <TextInput
                    source="representativeName"
                    label="locspect.resource.workOrders.fields.representativeName"
                    validate={[required()]}
                    fullWidth
                />
            </Grid>
            {siteContent}

            <Grid item xs={4} sm={4} md={4}>
                {props.role === "ADMIN" ?
                    <FormDataConsumer>
                        {({ formData, ...rest }) => (
                            <ReferenceInput
                                source="assignedToId"
                                reference={ENDPOINTS.users.name}
                                filter={{
                                    "userCompanyRole.role_eq": 'SUPERVISOR'
                                }}
                                sort={{ field: "user.firstName", order: 'ASC' }}
                                perPage={2000}
                            >
                                <SelectInput emptyValue={""} emptyText={""} defaultValue={record && record.assignedToId ? record.assignedToId : undefined} validate={[]} fullWidth label="locspect.resource.workOrders.fields.assignedTo" {...rest} />
                            </ReferenceInput>
                        )}
                    </FormDataConsumer> :
                    <TextInput
                        label="locspect.resource.workOrders.fields.assignedTo"
                        source="assignedToId"
                        fullWidth
                        hidden
                        disabled
                        style={{ display: 'none' }}
                    />}
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
                <TextInput
                    label="locspect.resource.workOrders.fields.costCenter"
                    source="costCenterId"
                    fullWidth
                />
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
                <TextInput
                    label="locspect.resource.workOrders.fields.poWoNumber"
                    source="poWoNumber"
                    fullWidth
                />
            </Grid>
            {mode === "EDIT" &&
                (<>
                    <Grid item xs={4} sm={4} md={6}>
                        <TextInput
                            defaultValue={creator}
                            disabled
                            label="locspect.resource.workOrders.fields.createdBy"
                            source="name"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={4} sm={4} md={6}>
                        <DateInput fullWidth disabled source='createdAt' label="locspect.resource.workOrders.fields.createdAt" />
                    </Grid>
                    <Grid item xs={4} sm={4} md={6}>
                        <TextInput
                            defaultValue={updator}
                            disabled
                            label="locspect.resource.workOrders.fields.updatedBy"
                            source="name"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={4} sm={4} md={6}>
                        <DateInput fullWidth disabled source='updatedAt' label="locspect.resource.workOrders.fields.updatedAt" />
                    </Grid>
                </>)
            }
            <Grid item xs={4} sm={8} md={12}>
                <TextInput multiline rows={8} source="description" label={"locspect.resource.workOrders.fields.description"} validate={[required()]} fullWidth />
            </Grid>
            <WorkOrderImageList isPrinting={isPrinting} record={record} status={status} source={"beforeDocuments"} translationLabel={"locspect.resource.workOrders.fields.beforePicture"} type={type} role={props.role} />
            <WorkOrderImageList isPrinting={isPrinting} record={record} status={status} source={"afterDocuments"} translationLabel={"locspect.resource.workOrders.fields.afterPicture"} type={type} role={props.role} />
        </Grid>)
});

const WorkOrderForm = (props) => {
    const [isPrinting, setIsPrinting] = useState(false);
    const componentRef = useRef();

    // We store the resolve Promise being used in `onBeforeGetContent` here
    const promiseResolveRef = useRef(null);

    // We watch for the state to change here, and for the Promise resolve to be available
    useEffect(() => {
        if (isPrinting && promiseResolveRef.current) {
            // Resolves the Promise, letting `react-to-print` know that the DOM updates are completed
            promiseResolveRef.current();
        }
    }, [isPrinting]);

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        onBeforeGetContent: () => {
            return new Promise((resolve) => {
                promiseResolveRef.current = resolve;
                setIsPrinting(true);
            });
        },
        onAfterPrint: () => {
            document.activeElement.blur();
            promiseResolveRef.current = null;
            componentRef.current.focus();
            setIsPrinting(false);
        },
    });

    const handleKeyDown = useCallback(
        (event) => {
            if (event.metaKey && event.key === "p") {
                event.preventDefault();
                handlePrint();
            }
        },
        [handlePrint]
    );

    useEffect(() => {
        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [handleKeyDown]);

    const UserEditToolbar = (props) => {
        const isMd = useMediaQuery((theme) => theme.breakpoints.down('md'));
        return (
            <Toolbar {...props} >
                <SaveButton fullWidth={isMd} size='large' type="button"
                    transform={(data) => {
                        return {
                            ...data,
                            workOrderOtherLocation: data.companySiteId ? undefined : data.workOrderOtherLocation
                        }
                    }}
                />
            </Toolbar>
        )
    };

    const translate = useTranslate();

    return (
        <>
            <Grid pl={3} pt={2} container spacing={{ xs: 2, md: 4 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                <Grid item xs={4} sm={8} md={1.5}>
                    <ListButton fullWidth={props.mode === "EDIT"} variant='contained' size='large' label="locspect.resource.menu.back" icon={<ChevronLeft />} />
                </Grid>
                {props.mode === "EDIT" && <Grid item xs={4} sm={8} md={1.5} justifyContent="flex-end">
                    <Button fullWidth size='large' variant="contained" onClick={handlePrint} disabled={isPrinting}>
                        {!isPrinting ? <><PrintOutlined /> {translate("locspect.resource.workOrders.fields.print")} </> : <CircularProgress size={27} />}
                    </Button>
                </Grid>}
            </Grid>
            <SimpleForm toolbar={<UserEditToolbar />}>
                <WorkOrderEditComponent isPrinting={isPrinting} ref={componentRef} mode={props.mode} managedcompanies={props.managedcompanies} role={props.role} />
            </SimpleForm>
            <Grid px={3} pb={3} container columns={{ xs: 4, sm: 8, md: 12 }}>
                <Typography variant="h5" component="h5">
                    {translate("locspect.resource.workOrders.fields.history")}
                </Typography>
                <Grid item xs={4} sm={8} md={12}>
                    <ArrayField source="history">
                        <Datagrid bulkActionButtons={false}>
                            <TextField label="locspect.resource.workOrders.fields.number" source="woNumber" />
                            <FunctionField
                                label="locspect.resource.workOrders.fields.companySite"
                                render={record => (record && record.companySite) ? record.companySite.site.siteName : record.workOrderOtherLocation.siteName}
                            />
                            <FunctionField
                                label="locspect.resource.workOrders.fields.company"
                                render={record => (record && record.companySite) ? record.companySite.site.company.companyName : record.workOrderOtherLocation.company.companyName}
                            />
                            <TextField label="locspect.resource.workOrders.fields.representativeName" source="representativeName" />
                            <TextField label="locspect.resource.workOrders.fields.costCenter" source="costCenterId" />
                            <TextField label="locspect.resource.workOrders.fields.poWoNumber" source="poWoNumber" />
                            <DateField label="locspect.resource.workOrders.fields.requestedStartDate" source="requestedStartDate" />
                            <DateField label="locspect.resource.workOrders.fields.startDate" source="startDate" />
                            <DateField label="locspect.resource.workOrders.fields.endDate" source="endDate" />
                            <FunctionField label="locspect.resource.workOrders.fields.status" render={record => translate('locspect.resource.workOrders.fields.statusInput.' + record.status)} />
                            <FunctionField label="locspect.resource.workOrders.fields.type" render={record => translate('locspect.resource.workOrders.fields.typeInput.' + record.type)} />
                            <FunctionField label="locspect.resource.workOrders.fields.createdBy" render={record => `${record.createdBy.user.firstName} ${record.createdBy.user.lastName}`} />
                            <DateTimeField label="locspect.resource.workOrders.fields.createdAt" source="createdAt" />
                            <FunctionField label="locspect.resource.workOrders.fields.updatedBy" render={record => record.updatedBy ? `${record.updatedBy.user.firstName} ${record.updatedBy.user.lastName}` : ''} />
                            <DateTimeField label="locspect.resource.workOrders.fields.updatedAt" source="updatedAt" />
                        </Datagrid>
                    </ArrayField>
                </Grid>
            </Grid>
        </>
    );
}


export const WorkOrderCreate = (props) => {
    return (
        <Create>
            <WorkOrderForm mode="ADD" managedcompanies={props.managedcompanies} role={props.role} />
        </Create>
    );
};

export const WorkOrderEdit = (props) => {
    const refresh = useRefresh();
    const redirect = useRedirect();

    const onSuccess = (data) => {
        redirect('/workOrders/' + data.id);
        refresh();
    };
    return (
        <Edit mutationMode='pessimistic' mutationOptions={{ onSuccess }}>
            <WorkOrderForm mode="EDIT" managedcompanies={props.managedcompanies} role={props.role} />
        </Edit>
    );
};

