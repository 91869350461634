import * as React from 'react';
import { Divider, useMediaQuery } from '@mui/material';
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  SimpleList,
  TextInput,
  downloadCSV,
  Edit,
  SelectInput,
  EditButton,
  SaveButton,
  useRecordContext,
  TabbedForm,
  ReferenceManyField,
  useCreate,
  useRefresh,
  useNotify,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  useDelete,
  DeleteWithConfirmButton,
  DatagridConfigurable,
} from 'react-admin';
import jsonExport from 'jsonexport/dist';
import { AsYouType } from 'libphonenumber-js';
import PhoneField from '../../field/PhoneField';
import { DefaultPagination } from '../../pagination/Pagination';
import { ENDPOINTS } from '../../api/JsonDataProvider';
import { useFormContext } from "react-hook-form";
import { DefaultListActions, FieldWrapper } from '../../actions/ListActions';


const exporter = (users) => {
  const usersForExport = users.map((user) => {
    let userForExport = {};
    userForExport.first_name = user.user.firstName;
    userForExport.last_name = user.user.lastName;
    userForExport.email = user.user.email;
    userForExport.phone = user.user.phone;
    userForExport.role = user.userCompanyRole.role;
    userForExport.erailSafe = user.erailSafe;
    return userForExport;
  });
  jsonExport(
    usersForExport,
    {
      headers: ['first_name', 'last_name', 'email', 'phone', 'role', 'erailSafe'],
    },
    (err, csv) => {
      downloadCSV(csv, 'user_list');
    }
  );
};

const filter = [
  <TextInput source="user->firstName_like" label="locspect.resource.employees.fields.firstName" />,
  <TextInput source="user->lastName_like" label="locspect.resource.employees.fields.lastName" />,
  <TextInput source="user->email_like" label="locspect.resource.employees.fields.email" />,
  <TextInput source="user->phone_like" label="locspect.resource.employees.fields.phone" />,
  <TextInput source="userCompanyRole->role_like" label="locspect.resource.employees.fields.role" />,
  <TextInput source="eRailSafe_like" label="locspect.resource.employees.fields.eRailSafe" />,
];

export const CompanyUserList = () => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  return (
    <List actions={<DefaultListActions />} perPage={25} pagination={<DefaultPagination />} filters={filter} exporter={exporter}>
      {isSmall ? (
        <SimpleList
          primaryText={(record) => `${record.user.firstName} ${record.user.lastName}`}
          secondaryText={(record) => `${record.user.email}`}
          tertiaryText={(record) => `${record ? new AsYouType('US').input(record.user.phone) : ''}`}
        />
      ) : (
        <DatagridConfigurable bulkActionButtons={false}>
          <TextField label="locspect.resource.employees.fields.firstName" source="user.firstName" />
          <TextField label="locspect.resource.employees.fields.lastName" source="user.lastName" />
          <EmailField label="locspect.resource.employees.fields.email" source="user.email" />
          <PhoneField label="locspect.resource.employees.fields.phone" source="user.phone" />
          <TextField
            label="locspect.resource.employees.fields.role"
            source="userCompanyRole.role"
          />
          <TextField
            label="locspect.resource.employees.fields.eRailSafe"
            source="erailSafe"
            sortBy="eRailSafe"
          />
          <FieldWrapper label="ra.action.edit">
            <EditButton />
          </FieldWrapper>
        </DatagridConfigurable>
      )}
    </List>
  );
};
const RoleSelectInput = ({ source }) => {
  const record = useRecordContext();

  return (
    <SelectInput
      defaultValue={record.userCompanyRole.role}
      source="role"
      choices={[
        { id: 'ADMIN', name: 'ADMIN' },
        { id: 'SUPERVISOR', name: 'SUPERVISOR' },
        { id: 'EMPLOYEE', name: 'EMPLOYEE' },
        { id: 'CONTRACTOR', name: 'CONTRACTOR' },
        { id: 'DISABLED', name: 'DISABLED' },
      ]}
    />
  );
};

const CompanyUserEditToolbar = (props) => {
  return <></>;
};

const filterToQuery = searchText => ({ 'site.siteCode_like': `${searchText}` });

const MyDeleteButton = ({ usercompanyId }) => {
  const record = useRecordContext();
  const [deleteOne] = useDelete();
  const notify = useNotify();
  const refresh = useRefresh();

  const handleClick = () => {
    deleteOne(
      'usersCompaniesSitesDelete',
      {
        meta: {
          substitutes: [{
            target: 'userCompanyId',
            id: usercompanyId,
          },
          {
            target: 'siteId',
            id: record.id,
          }]
        }
      },
      {
        onSuccess: (data) => {
          if (data !== "MY_INVALID_CALL") {
            refresh();
            notify(`Site code deleted`, { type: 'success' });
          }
        },
        onError: (error) => {
          notify(`Site codes deleting error: ${error.message}`, { type: 'warning' });
        }
      }
    );
  }

  return <DeleteWithConfirmButton onClick={handleClick} mutationMode='pessimistic' translateOptions={{ name: record.siteName, id: record.siteCode }} />;
}

const UserCompanySiteTab = (props) => {
  const record = useRecordContext();
  const { getValues, reset } = useFormContext();
  const [create] = useCreate();
  const refresh = useRefresh();
  const notify = useNotify();

  const handleClick = async e => {
    e.preventDefault();
    const { managedSiteIds } = getValues();
    create(
      'usersCompaniesSites',
      {
        data: { managedSiteIds: managedSiteIds },
        meta: {
          target: 'userCompanyId',
          id: record.id,
        },
      },
      {
        onSuccess: (data) => {
          reset();
          refresh();
          notify(`Site code added`, { type: 'success' });
        },
        onError: (error) => {
          notify(`Site codes adding error: ${error.message}`, { type: 'warning' });
        },
      }
    );
  };



  if (record.userCompanyRole.role === 'SUPERVISOR') {
    return (
      <TabbedForm.Tab label="Sites" {...props}>
        <ReferenceManyField
          perPage={25}
          pagination={<DefaultPagination />}
          reference="usersCompaniesSites"
          target="userCompanyId"
          label={false} >
          <Datagrid bulkActionButtons={false}>
            <TextField label="locspect.resource.managedSites.fields.code" source="siteCode" />
            <TextField label="locspect.resource.managedSites.fields.siteName" source="siteName" />
            <TextField label="locspect.resource.managedSites.fields.companyName" source="company.companyName" />
            <TextField label="locspect.resource.managedSites.fields.state" source="address.state" />
            <TextField label="locspect.resource.managedSites.fields.country" source="address.country" />
            <MyDeleteButton usercompanyId={record.id} />
          </Datagrid>
        </ReferenceManyField>
        <Divider flexItem style={{ marginBottom: 5 }} />

        <ReferenceArrayInput options={{ fullWidth: true }} source="managedSiteIds" label="locspect.resource.managedSites.fields.siteName" reference={ENDPOINTS['managed/company/sites'].name}>
          <AutocompleteArrayInput fullWidth filterToQuery={filterToQuery} optionText={(record) => record.site.siteName} />
        </ReferenceArrayInput>
        <SaveButton onClick={handleClick} />
      </TabbedForm.Tab>

    );
  }
  return <></>;
};

export const CompanyUserEdit = () => {
  return (
    <Edit undoable={false}>
      <TabbedForm toolbar={<CompanyUserEditToolbar />}>
        <TabbedForm.Tab label="Information">
          <TextInput
            disabled
            label="locspect.resource.employees.fields.firstName"
            source="user.firstName"
          />
          <TextInput
            disabled
            label="locspect.resource.employees.fields.lastName"
            source="user.lastName"
          />
          <TextInput
            disabled
            label="locspect.resource.employees.fields.email"
            source="user.email"
          />
          <TextInput
            disabled
            label="locspect.resource.employees.fields.phone"
            source="user.phone"
          />
          <TextInput label="locspect.resource.employees.fields.eRailSafe" source="erailSafe" />
          <RoleSelectInput label="locspect.resource.employees.fields.role" source="role" />
          <Divider flexItem style={{ marginBottom: 5 }} />
          <SaveButton />
        </TabbedForm.Tab>
        <UserCompanySiteTab></UserCompanySiteTab>
      </TabbedForm>
    </Edit>
  );
};
